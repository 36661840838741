import {MonthAsInt} from "./month";
import {ChannelId, ChannelShortView} from "./channel";
import {FilmId, FilmToHumanView} from "./film";
import {MediaPlanId, MediaPlanToHumanView} from "./mediaPlan";
import {MediaPlanItemId} from "./mediaPlanItem";
import {TaskId} from "./task";

export type TaskItemId = number;

export type TaskItemShortDisplayView = {
    id: TaskItemId,
    task_id: TaskId,
    month: MonthAsInt,
    mp_id: MediaPlanId,
    mp_item_id: MediaPlanItemId,
    mp_name: string,
    filmId: FilmId,
    film_name: string,
    film_version: string,
    channel_id: ChannelId,
    channel: ChannelShortView,
    extend: {
        mediaPlan: MediaPlanToHumanView,
        film: FilmToHumanView,
    },
}