declare var angular: any
declare var _: any

type PlanFact = {
    plan: number,
    fact: number,
}

angular.module("app")
    .component("cTaskItemAnalyticsTab", {
        templateUrl: "/components/_c-task-item-analytics-tab.html",
        bindings: {
            task: "=",
            filterItems: "=",
        },
        controller: function (
            $scope,
            _taskItems
        ) {

        }
    })
    .component("lcTaskItemAnalyticsPlanFactAggregatedDataByGroup", {
        templateUrl: "lc-task-item-analytics-plan-fact-aggregated-data-by-group",
        bindings: {
            task: "=",
            filterItems: "=",
        },
        controller: function (
            $scope,
            _taskItems
        ) {
            const $ctrl = this

            $ctrl.isLoading = true

            _taskItems.api.book.analytics.planFactAggregatedDataByGroup($ctrl.task.id).then(data => {
                $ctrl.data = data
                $ctrl.isLoading = false
            })
        }
    })
    .component("lcTaskItemAnalyticsPlanFactAggregatedDataByGroupTable", {
        templateUrl: "lc-task-item-analytics-plan-fact-aggregated-data-by-group-table",
        bindings: {
            data: "=",
            isLoading: "=",
        },
        controller: function ($scope, _percentageOfFilter, _percentageToHumanFilter, _bookGoalToHumanFilter) {
            const $ctrl = this

            $ctrl.table = {
                keyHumansFullView: [],
                getKeyHumanToDisplay: row => {
                    if (row.keyHuman.length < 50 || $ctrl.table.keyHumansFullView.contains(row.keyAsString)) {
                        return row.keyHuman
                    } else {
                        return row.keyHuman.substring(0, 50) + "..."
                    }
                },
                toggleDisplayKeyHuman: row => {
                    $ctrl.table.keyHumansFullView.addOrRemove(row.keyAsString)
                },
            }

            $scope.$watch("$ctrl.data", () => {
                if ($ctrl.data) {
                    $ctrl.table.rows = _.sortBy(
                        $ctrl.data.map(row => {
                            const isMinute = row.key.is_minute

                            const getPlanFactWithPercentage = (planFact: PlanFact, isBookGoal: boolean, roundPrecision?: number, relativeToPlan?: number) => {
                                const getDisplayValue = (amount: number): string => {
                                    if (relativeToPlan) {
                                        return _percentageToHumanFilter(_percentageOfFilter(amount, relativeToPlan))
                                    } else {
                                        return (isBookGoal) ? _bookGoalToHumanFilter(amount, isMinute, 1) : _.roundTo(amount, roundPrecision || 2);
                                    }
                                }

                                const planDisplay = getDisplayValue(planFact.plan);
                                const factDisplay = getDisplayValue(planFact.fact);

                                const displayPercentage = (() => {
                                  if (planFact.plan) {
                                    if (relativeToPlan) {
                                      return _percentageOfFilter(planFact.fact, relativeToPlan) - _percentageOfFilter(planFact.plan, relativeToPlan)
                                    } else {
                                      return _percentageOfFilter(planFact.fact, planFact.plan) - 100
                                    }
                                  }

                                  return null
                                })()

                                return {
                                    plan: planFact.plan,
                                    planDisplay: (planFact.plan) ? planDisplay : "",
                                    fact: planFact.fact,
                                    factDisplay: (planFact.plan) ? factDisplay : "",
                                    percentage: displayPercentage,
                                }
                            }

                            const keyHuman = (() => {
                                const taskItems = _.groupBy(row.extend.task_items, ti => {
                                    return [ti.mp_id].join("::")
                                })

                                const keys = Object.keys(taskItems).map(key => {
                                    const taskItemsCurrent = taskItems[key]
                                    const keyData = taskItemsCurrent[0]

                                    const taskItemsHuman = taskItemsCurrent.map(ti => {
                                        return `(${ti.film_id}) ${ti.film_name} ${ti.film_version}`
                                    })

                                    return `(${keyData.mp_id}) ${keyData.mp_name}: ${taskItemsHuman.join(", ")}`
                                })

                                return keys.join("; ")
                            })();

                            const channels = row.extend.channels || []

                            return {
                                bookGoal: getPlanFactWithPercentage(row.book_goal, true),
                                bookGoalNormalized: getPlanFactWithPercentage(row.book_goal_normalized, true),
                                budget: getPlanFactWithPercentage(row.budget, false),
                                prime: getPlanFactWithPercentage(row.prime, true),
                                primeNormalized: getPlanFactWithPercentage(row.prime_normalized, true, 2, row.book_goal_normalized?.plan),
                                trp: getPlanFactWithPercentage(row.trp, false, 1),
                                trpNormalized: getPlanFactWithPercentage(row.trp_normalized, false, 1),
                                affinity: getPlanFactWithPercentage(row.affinity, false),
                                keyHuman: keyHuman,
                                channels: channels,
                                channelDisplayOrderIndex: channels[0]?.display_order_index,
                                keyAsString: JSON.stringify(row.key),
                            }
                        }),
                        r => r.channelDisplayOrderIndex
                    )
                }
            })
        }
    });
