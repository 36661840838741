import type {IHttpPromise, IPromise} from 'angular';
import {CollectionEntity} from '../domain/collectionEntity';

declare var angular: any
declare var _: any

export class HttpService {

    getData<T>(promise: IHttpPromise<T>): IPromise<T> {
        return promise.then(response => response.data)
    }

    getDataAndTransform<T, R>(promise: IHttpPromise<T>, transformer: (data: T) => R): IPromise<R> {
        return this.getData(promise).then(transformer)
    }

    getListDataAndTransform<I, R>(promise: IHttpPromise<CollectionEntity<I>>, transformer: (data: I) => R): IPromise<CollectionEntity<R>> {
        return this.getData(promise).then(data => {
            if (data) {
                if (data.items) {
                    data.items = _.map(data.items, function (item) {
                        return transformer(item);
                    });
                }
            }

            return data as any;
        })
    }

}

angular.module("app")
    .factory("_httpTs", function () {
            return new HttpService()
        }
    )
