export type Dictionary<T> = { [key: string]: T }

export function mapBy<K, V>(items: IterableIterator<V> | ArrayLike<V>, keyProvider: (item: V) => K): Map<K, V> {
    return mapByTransform(items, keyProvider, r => r)
}

export function mapByTransform<K, V, S>(items: IterableIterator<S> | ArrayLike<S>, keyProvider: (item: S) => K, valueProvider: (item: S) => V): Map<K, V> {
    const answer = new Map<K, V>()

    Array.from(items).forEach(item => {
        answer.set(keyProvider(item), valueProvider(item))
    })

    return answer
}