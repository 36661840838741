import {QueueTask, QueueTaskStatus} from './domain/queueTask';

declare const angular: any
declare const moment: any

export type QueueTaskTransformerService = (queueTask: QueueTask) => QueueTask

angular.module("app")
    .filter("_queueTaskStatusToHuman", function (_i18nconst) {
        return function (status: QueueTaskStatus) {
            if (status) {
                return _i18nconst.getString("queueTask.status." + status)
            } else {
                return "";
            }
        }
    })
    .factory("_queueTaskTransformer", function (_convert) {
        const answer: QueueTaskTransformerService = (queueTask: QueueTask) => {
            queueTask.started_at = _convert.momentOrNull(queueTask.started_at)
            queueTask.finished_at = _convert.momentOrNull(queueTask.finished_at)
            queueTask.posted_on = _convert.momentOrNull(queueTask.posted_on)
            queueTask.queued_on = _convert.momentOrNull(queueTask.queued_on)
            queueTask.status_on = _convert.momentOrNull(queueTask.status_on)

            return queueTask;
        }

        return answer
    })
    .component("cQueueTaskStatus", {
        template: '<span class="label" ng-class="$ctrl.labelClass" ng-bind="$ctrl.labelTranslation"></span>',
        bindings: {
            status: "="
        },
        controller: function ($scope, _queueTaskStatusToHumanFilter) {
            const $ctrl = this;

            const classes = (() => {
                const answer = {}

                answer[QueueTaskStatus.NEW] = "label-plain"
                answer[QueueTaskStatus.IN_PROGRESS] = "label-primary"
                answer[QueueTaskStatus.SUCCESS] = "label-success"
                answer[QueueTaskStatus.FAILURE] = "label-warning"
                answer[QueueTaskStatus.EXCEPTION] = "label-danger"

                return answer
            })()

            $scope.$watch("$ctrl.status", function () {
                refresh();
            });

            function refresh() {
                const status = $ctrl.status;
                const clazz = classes[status];
                const translation = _queueTaskStatusToHumanFilter(status);

                if (clazz && translation) {
                    $ctrl.labelClass = clazz;
                    $ctrl.labelTranslation = translation;
                }
            }
        }
    })
    .filter("_queueTaskTypeToHuman", function (_i18nconst) {
        return function (type: string) {
            if (type) {
                return _i18nconst.getString("queueTask.type." + type)
            } else {
                return ""
            }
        }
    })
