export class ComputableMap<K, V> {

    private map: Map<K, V> = new Map<K, V>()

    constructor(private computator: (key: K) => V) {
    }

    get(key: K): V {
        let answer = this.map.get(key)

        if (answer === undefined) {
            answer = this.computator(key);

            this.map.set(key, answer)
        }

        return answer;
    }

    asMap(): Map<K, V> {
        return this.map
    }

}