import {Channel, ChannelId, ChannelShortView} from "./domain/channel";
import {MonthAsInt} from "./domain/month";
import {SpotId} from "./domain/spot";
import {BlockId} from "./domain/block";
import {BookGoal} from "./domain/book";
import {TaskItemId, TaskItemShortDisplayView} from "./domain/taskItem";
import {
    TaskItemBookStrategyPredictStats,
    TaskItemBookStrategyPredictStatsStats,
    TaskItemInventoryStats,
    TaskItemSpotsFilterStats
} from "./domain/taskItemStats";
import {Dictionary, mapByTransform} from "./common/utils";
import {ComputableMap} from "./common/computableMap";
import {MediaPlanToHumanView} from "./domain/mediaPlan";
import {FilmToHumanView} from "./domain/film";
import {UniqueMap} from "./common/uniqueMap";

declare var angular: any
declare var _: any

enum UnitType {
    BOOK_GOAL = 'BOOK_GOAL',
    COUNT = 'COUNT'
}

type TableRow = {
    key: string,
    level: number,
    hasChildren: boolean,
    groupKeyTypes: GroupKeyType[],
    channelId?: ChannelId,
    month?: MonthAsInt,
    taskItemId?: TaskItemId,
    filterReason?: string,
    spots: BlockSpotsFilteredCountedDataChange,
    blocks: BlockSpotsFilteredCountedDataChange,
    extend: {
        mediaPlan?: MediaPlanToHumanView,
        film?: FilmToHumanView,
        channel?: ChannelShortView,
    },
}

type StatsRawItem = {
    channelId: ChannelId,
    month: MonthAsInt,
    taskItemId: TaskItemId,
    filterReason: string,
    spotIds: SpotId[],
    blockIds: BlockId[],
}

type BlockSpotsFilteredCountedData = {
    filtered: { count: number, bookGoal: BookGoal },
    total: { count: number, bookGoal: BookGoal },
}

type BlockSpotsFilteredCountedDataChange = {
    prev: BlockSpotsFilteredCountedData,
    current: BlockSpotsFilteredCountedData,
}

enum GroupKeyType {
    CHANNEL_MONTH = 'CHANNEL_MONTH',
    TASK_ITEM_ID = 'TASK_ITEM_ID',
    FILTER_REASON = 'FILTER_REASON',
}

type StatsGroupedItem = {
    key: string,
    groupKeyTypes: GroupKeyType[],
    channelId?: ChannelId,
    month?: MonthAsInt,
    taskItemId?: TaskItemId,
    filterReason?: string,
    spots: BlockSpotsFilteredCountedData,
    blocks: BlockSpotsFilteredCountedData,
    children?: StatsGroupedItem[]
}

type StatsGroupedChangedItem = {
    key: string,
    groupKeyTypes: GroupKeyType[],
    channelId?: ChannelId,
    month?: MonthAsInt,
    taskItemId?: TaskItemId,
    filterReason?: string,
    spots: {
        prev: BlockSpotsFilteredCountedData,
        current: BlockSpotsFilteredCountedData,
    },
    blocks: {
        prev: BlockSpotsFilteredCountedData,
        current: BlockSpotsFilteredCountedData,
    },
    children?: StatsGroupedChangedItem[]
}


angular.module("app")
    .filter("_cBookingStrategyPredictStatsTableRowLabel", function (
        _mediaPlanToHumanFilter,
        _filmToHumanWithVersionFilter,
        _spotOrBlockFilterReasonToHumanFilter,
    ) {
        return (row: TableRow) => {
            const groupKeyType = row.groupKeyTypes[row.groupKeyTypes.length - 1]

            switch (groupKeyType) {
                case GroupKeyType.TASK_ITEM_ID:
                    return _mediaPlanToHumanFilter(row.extend.mediaPlan) + " / " + _filmToHumanWithVersionFilter(row.extend.film)
                case GroupKeyType.FILTER_REASON:
                    return _spotOrBlockFilterReasonToHumanFilter(row.filterReason)
                case GroupKeyType.CHANNEL_MONTH:
                    return row.extend.channel.name
            }
        }
    })
    .component("cBookingStrategyPredictStatsBody", {
        templateUrl: "/components/_c-booking-strategy-predict-stats-body.html",
        bindings: {
            taskItems: "=",
            stats: "=",
            isLoadingData: "=",
        },
        controller: function (
            $scope,
            _filter,
            _filterItem,
            _cBookingStrategyPredictStatsTableRowLabelFilter,
        ) {
            const $ctrl = this;
            // const stats: Map<TaskItemId, TaskItemBookStrategyPredictStats> = new Map(($ctrl.stats as TaskItemBookStrategyPredictStats[]).map(s => [s.task_item_id, s]));

            (function configureGroupCriteria() {
                $ctrl.groupCriteria = {
                    _container: {},
                    get: (): GroupKeyType[] => {
                        return $ctrl.groupCriteria.data.getSelectedAsObjects("taskItemsDataGroupCriteria").map(item => {
                            return item.id as GroupKeyType
                        })
                    },
                    isEmpty: (): boolean => {
                        return $ctrl.groupCriteria.get().length === 0
                    },
                    data: (() => {
                        const answer = _filter.init({
                            getSelectedContainer: function () {
                                return $ctrl.groupCriteria._container;
                            },
                            onChanged: (selected) => {
                                $ctrl.table._refresh();
                            },
                        })

                        answer.setItems(
                            [
                                [GroupKeyType.TASK_ITEM_ID, "by task items"],
                                [GroupKeyType.CHANNEL_MONTH, "by channel"],
                                [GroupKeyType.FILTER_REASON, "by filter reason"],
                            ].map(data => {
                                return _filterItem.init({
                                    id: data[0],
                                    type: "taskItemsDataGroupCriteria",
                                    label: data[1],
                                })
                            })
                        )

                        return answer
                    })(),
                    reset: function () {
                        $ctrl.groupCriteria.data.setSelected([]);
                    },
                    byTaskItems: function () {
                        $ctrl.groupCriteria.data.addSelected(_filterItem.toTaskItemsDataGroupCriteria(GroupKeyType.TASK_ITEM_ID))
                    },
                    byChannel: function () {
                        $ctrl.groupCriteria.data.addSelected(_filterItem.toTaskItemsDataGroupCriteria(GroupKeyType.CHANNEL_MONTH))
                    },
                    byFilterReason: function () {
                        $ctrl.groupCriteria.data.addSelected(_filterItem.toTaskItemsDataGroupCriteria(GroupKeyType.FILTER_REASON))
                    },
                }
            })();

            (function configureTable() {
                $ctrl.table = {
                    rows: [],
                    toggle: (row: TableRow) => {
                        if ($ctrl.table.canToggle(row)) {
                            $ctrl.table._expandedKeys.addOrRemove(row.key)
                            $ctrl.table._refreshRows()
                        }
                    },
                    callServer: function (tableState) {
                        $ctrl.table._state = tableState;
                        $ctrl.table._refreshRows();
                    },
                    canToggle: (row: TableRow) => {
                        return row.hasChildren
                    },
                    getRowClass: (row: TableRow) => {
                        const answer: string[] = ["-row _level-" + row.level]

                        if ($ctrl.table.canToggle(row)) {
                            answer.push("_expandable")

                            if ($ctrl.table._isExpanded(row.key)) {
                                answer.push("_expanded")
                            }
                        }

                        return answer
                    },
                    _expandedKeys: [],
                    _isExpanded: (key: string): boolean => {
                        return $ctrl.table._expandedKeys.includes(key)
                    },
                    _refresh: () => {
                        $ctrl.table.model = $ctrl.table._getModel()
                        $ctrl.table.channelIds = $ctrl.table._getChannelIds()
                        $ctrl.table._refreshRows()
                    },
                    _refreshRows: () => {
                        const taskItems: Dictionary<TaskItemShortDisplayView> = $ctrl.taskItems
                        const channels = mapByTransform(Object.keys(taskItems).map(taskItemId => taskItems[taskItemId]), r => r.channel_id, r => r.channel);

                        const sort = (items: TableRow[]): TableRow[] => {
                            const sortConfig = $ctrl.table._state?.sort || {
                                predicate: "name",
                                reverse: false,
                            }

                            const answer = _.sortBy(items, (item: TableRow) => {
                                const predicate = sortConfig.predicate || "name"

                                if (predicate === "name") {
                                    return _cBookingStrategyPredictStatsTableRowLabelFilter(item)
                                } else if (predicate === "blocks") {
                                    return item.blocks.current.filtered.bookGoal
                                } else if (predicate === "spots") {
                                    return item.spots.current.filtered.bookGoal
                                }
                            })

                            if (sortConfig.reverse) {
                                answer.reverse()
                            }

                            return answer
                        }

                        const collect = (items: StatsGroupedChangedItem[], level: number, isExpandedParent: boolean): TableRow[] => {
                            const answerCurrent: TableRow[] = []
                            const rows: TableRow[] = []
                            const rowsChildren = {}

                            items.forEach(item => {
                                if (level === 0 || isExpandedParent) {
                                    const taskItem = (item.taskItemId) ? taskItems[item.taskItemId.toString()] : null
                                    const channel = (item.channelId) ? channels.get(item.channelId) : null

                                    rows.push({
                                        key: item.key,
                                        level: level,
                                        groupKeyTypes: item.groupKeyTypes,
                                        hasChildren: !!item.children.length,
                                        channelId: item.channelId,
                                        month: item.month,
                                        taskItemId: item.taskItemId,
                                        filterReason: item.filterReason,
                                        spots: item.spots,
                                        blocks: item.blocks,
                                        extend: {
                                            channel: channel,
                                            film: taskItem?.extend?.film,
                                            mediaPlan: taskItem?.extend?.mediaPlan,
                                        },
                                    })

                                    rowsChildren[item.key] = collect(item.children, level + 1, $ctrl.table._isExpanded(item.key))
                                }
                            })

                            sort(rows).forEach(row => {
                                answerCurrent.push(row)
                                answerCurrent.push(...rowsChildren[row.key] || [])
                            })

                            return answerCurrent
                        }

                        $ctrl.table.rows = collect($ctrl.table.model, 0, false)
                    },
                    _getStatsRawItems: (
                        stats: Map<TaskItemId, TaskItemBookStrategyPredictStats>,
                        statsStateProvider: (stats: TaskItemBookStrategyPredictStats) => TaskItemBookStrategyPredictStatsStats,
                        filterReasonKeys: {
                            blocks: Set<string>,
                            spots: Set<string>,
                            total: Set<string>,
                        },
                    ): {
                        filtered: StatsRawItem[],
                        notFiltered: StatsRawItem[],
                    } => {
                        const filtered: StatsRawItem[] = []
                        const notFiltered: StatsRawItem[] = []
                        const taskItems: Dictionary<TaskItemShortDisplayView> = $ctrl.taskItems
                        const blockByTaskItemIds = mapByTransform(stats.values(), r => r.task_item_id, r => statsStateProvider(r).blocks);
                        const spotByTaskItemIds = mapByTransform(stats.values(), r => r.task_item_id, r => statsStateProvider(r).spots);
                        const filterReasonKeysTotal = new Set([...filterReasonKeys.blocks, ...filterReasonKeys.spots])

                        const statsItemCreateEmpty = (taskItem: TaskItemShortDisplayView, filterReason?: string): StatsRawItem => {
                            return {
                                channelId: taskItem.channel_id,
                                month: taskItem.month,
                                taskItemId: taskItem.id,
                                filterReason: filterReason,
                                spotIds: [],
                                blockIds: [],
                            }
                        }

                        Object.keys(taskItems).map(taskItemId => {
                            const taskItem: TaskItemShortDisplayView = taskItems[taskItemId]
                            const taskItemStats: TaskItemBookStrategyPredictStats = stats.get(taskItem.id)

                            if (taskItemStats) {
                                const taskItemBlockIds = Object.keys(taskItemStats.block_details).map(blockId => +blockId)
                                const taskItemSpotIds = Object.keys(taskItemStats.spot_details).map(spotId => +spotId)
                                const filteredCurrentByFilter = new ComputableMap<string, StatsRawItem>(filter => statsItemCreateEmpty(taskItem, filter))
                                const notFilteredCurrentByFilter = new ComputableMap<string, StatsRawItem>(filter => statsItemCreateEmpty(taskItem, filter))
                                const blocks: TaskItemInventoryStats = blockByTaskItemIds.get(+taskItemId)
                                const spots: TaskItemSpotsFilterStats = spotByTaskItemIds.get(+taskItemId)

                                filterReasonKeysTotal.forEach(filterReason => {
                                    const blockIds = blocks.filter_reasons[filterReason]?.program_break_ids || [];
                                    const notFilteredBlockIds = taskItemBlockIds.filter(blockId => !blockIds.includes(blockId))

                                    if (blockIds.length) {
                                        filteredCurrentByFilter.get(filterReason).blockIds.push(...blockIds)
                                    }

                                    notFilteredCurrentByFilter.get(filterReason).blockIds.push(...notFilteredBlockIds)
                                })

                                filterReasonKeysTotal.forEach(filterReason => {
                                    const spotIds = spots.filter_reasons[filterReason]?.spot_ids || [];
                                    const notFilteredSpotIds = taskItemSpotIds.filter(spotId => !spotIds.includes(spotId))

                                    if (spotIds.length) {
                                        filteredCurrentByFilter.get(filterReason).spotIds.push(...spotIds)
                                    }

                                    notFilteredCurrentByFilter.get(filterReason).spotIds.push(...notFilteredSpotIds)
                                })

                                filtered.push(...filteredCurrentByFilter.asMap().values())
                                notFiltered.push(...notFilteredCurrentByFilter.asMap().values())
                            }
                        })

                        return {
                            filtered: filtered,
                            notFiltered: notFiltered,
                        }
                    },
                    _groupRawItems: (
                        stats: Map<TaskItemId, TaskItemBookStrategyPredictStats>,
                        keyParent: string,
                        rawItemsFiltered: StatsRawItem[],
                        rawItemsNotFiltered: StatsRawItem[],
                        groupKeyTypesParent: GroupKeyType[],
                        groupKeyTypes: GroupKeyType[],
                    ): StatsGroupedItem[] => {
                        if (groupKeyTypes.length) {
                            const groupKeysChildren = [...groupKeyTypes]
                            const groupKeyType = groupKeysChildren.shift()
                            const groupKeyTypesCurrent = [...groupKeyTypesParent, groupKeyType]

                            const itemsFilteredGrouped = _.groupBy(rawItemsFiltered, item => $ctrl.table._getGroupKey(item, groupKeyType));
                            const itemsFilteredGroupKeys = Object.keys(itemsFilteredGrouped);
                            const itemsNotFilteredGrouped = _.groupBy(rawItemsNotFiltered, item => $ctrl.table._getGroupKey(item, groupKeyType));

                            return itemsFilteredGroupKeys.map(key => {
                                const itemsFilteredCurrent: StatsRawItem[] = itemsFilteredGrouped[key]
                                const itemsNotFilteredCurrent: StatsRawItem[] = itemsNotFilteredGrouped[key] || []
                                const itemFirst = itemsFilteredCurrent[0]

                                const blocks = $ctrl.table._groupRawItemsGetBlocks(stats, itemsFilteredCurrent, itemsNotFilteredCurrent, groupKeyTypesCurrent)
                                const spots = $ctrl.table._groupRawItemsGetSpots(stats, itemsFilteredCurrent, itemsNotFilteredCurrent, groupKeyTypesCurrent)

                                const keyCurrent = [keyParent, key].filter(r => !!r).join("::");

                                return {
                                    key: keyCurrent,
                                    groupKeyTypes: groupKeyTypesCurrent,
                                    channelId: (groupKeyTypesCurrent.includes(GroupKeyType.CHANNEL_MONTH)) ? itemFirst.channelId : null,
                                    month: (groupKeyTypesCurrent.includes(GroupKeyType.CHANNEL_MONTH)) ? itemFirst.month : null,
                                    taskItemId: (groupKeyTypesCurrent.includes(GroupKeyType.TASK_ITEM_ID)) ? itemFirst.taskItemId : null,
                                    filterReason: (groupKeyTypesCurrent.includes(GroupKeyType.FILTER_REASON)) ? itemFirst.filterReason : null,
                                    blocks: blocks,
                                    spots: spots,
                                    children: $ctrl.table._groupRawItems(
                                        stats,
                                        keyCurrent,
                                        itemsFilteredCurrent,
                                        itemsNotFilteredCurrent,
                                        groupKeyTypesCurrent,
                                        groupKeysChildren,
                                    )
                                }
                            })
                        } else {
                            return []
                        }
                    },
                    _groupRawItemsGetSpots: (
                        stats: Map<TaskItemId, TaskItemBookStrategyPredictStats>,
                        itemsFilteredCurrent: StatsRawItem[],
                        itemsNotFilteredCurrent: StatsRawItem[],
                        groupKeyTypes: GroupKeyType[],
                    ): BlockSpotsFilteredCountedData => {
                        return $ctrl.table._groupRawItemsGetBlocksSpots(
                            stats,
                            itemsFilteredCurrent,
                            itemsNotFilteredCurrent,
                            groupKeyTypes,
                            statsRawItems => {
                                return statsRawItems.spotIds
                                    .map(spotId => {
                                        const spot = stats.get(statsRawItems.taskItemId).spot_details[spotId.toString()];

                                        if (spot) {
                                            return {
                                                id: spotId,
                                                bookGoal: spot.book_goal,
                                                taskItemId: statsRawItems.taskItemId,
                                            }
                                        } else {
                                            return null
                                        }
                                    })
                                    .filter(r => !!r)
                            }
                        )
                    },
                    _groupRawItemsGetBlocks: (
                        stats: Map<TaskItemId, TaskItemBookStrategyPredictStats>,
                        itemsFilteredCurrent: StatsRawItem[],
                        itemsNotFilteredCurrent: StatsRawItem[],
                        groupKeyTypes: GroupKeyType[],
                    ): BlockSpotsFilteredCountedData => {
                        return $ctrl.table._groupRawItemsGetBlocksSpots(
                            stats,
                            itemsFilteredCurrent,
                            itemsNotFilteredCurrent,
                            groupKeyTypes,
                            statsRawItems => {
                                return statsRawItems.blockIds
                                    .map(blockId => {
                                        const block = stats.get(statsRawItems.taskItemId).block_details[blockId.toString()];

                                        if (block) {
                                            return {
                                                id: blockId,
                                                bookGoal: block.book_goal,
                                                taskItemId: statsRawItems.taskItemId,
                                            }
                                        } else {
                                            return null
                                        }
                                    })
                                    .filter(r => !!r)
                            }
                        )
                    },
                    _groupRawItemsGetBlocksSpots: (
                        stats: Map<TaskItemId, TaskItemBookStrategyPredictStats>,
                        itemsFilteredCurrent: StatsRawItem[],
                        itemsNotFilteredCurrent: StatsRawItem[],
                        groupKeyTypes: GroupKeyType[],
                        blockSpotsDataProvider: (statsRawItem: StatsRawItem) => {
                            id: any,
                            bookGoal: BookGoal,
                            taskItemId: TaskItemId,
                        }[]
                    ): BlockSpotsFilteredCountedData => {
                        const isTaskItemUnique = !groupKeyTypes.includes(GroupKeyType.FILTER_REASON)
                        const blocksSpotsCounted = new UniqueMap()

                        const answer: BlockSpotsFilteredCountedData = {
                            filtered: {
                                bookGoal: 0,
                                count: 0,
                            },
                            total: {
                                bookGoal: 0,
                                count: 0,
                            }
                        }

                        itemsFilteredCurrent.forEach(item => {
                            const blocksSpots = blockSpotsDataProvider(item);

                            blocksSpots.forEach(blockSpot => {
                                if (!isTaskItemUnique || blocksSpotsCounted.push(blockSpot.taskItemId, blockSpot.id)) {
                                    answer.filtered.bookGoal += blockSpot.bookGoal
                                    answer.filtered.count++
                                    answer.total.bookGoal += blockSpot.bookGoal
                                    answer.total.count++
                                }
                            })
                        })

                        itemsNotFilteredCurrent.forEach(item => {
                            const blocksSpots = blockSpotsDataProvider(item);

                            blocksSpots.forEach(blockSpot => {
                                if (!isTaskItemUnique || blocksSpotsCounted.push(blockSpot.taskItemId, blockSpot.id)) {
                                    answer.total.bookGoal += blockSpot.bookGoal
                                    answer.total.count++
                                }
                            })
                        })

                        return answer
                    },
                    _getGroupKey: (item: StatsRawItem, groupKeyType: GroupKeyType): string => {
                        switch (groupKeyType) {
                            case GroupKeyType.CHANNEL_MONTH:
                                return `${item.channelId}_${item.month}`
                            case GroupKeyType.FILTER_REASON:
                                return item.filterReason
                            case GroupKeyType.TASK_ITEM_ID:
                                return item.taskItemId.toString()
                        }
                    },
                    _getGroupedItems: (
                        stats: Map<TaskItemId, TaskItemBookStrategyPredictStats>,
                        statsStateProvider: (stats: TaskItemBookStrategyPredictStats) => TaskItemBookStrategyPredictStatsStats,
                        groupKeys: GroupKeyType[],
                        filterReasonKeys: {
                            blocks: Set<string>,
                            spots: Set<string>,
                        },
                    ): StatsGroupedItem[] => {
                        const rawItems = $ctrl.table._getStatsRawItems(stats, statsStateProvider, filterReasonKeys);

                        return $ctrl.table._groupRawItems(
                            stats,
                            '',
                            rawItems.filtered,
                            rawItems.notFiltered,
                            [],
                            groupKeys,
                        )
                    },
                    _getGroupedChangedItems: (prev: StatsGroupedItem[], current: StatsGroupedItem[]): StatsGroupedChangedItem[] => {
                        const flattenItems = (items: StatsGroupedItem[]) => {
                            const answer: StatsGroupedItem[] = []

                            const collectItemsDeep = (item: StatsGroupedItem) => {
                                answer.push(item);

                                (item.children || []).forEach(child => {
                                    collectItemsDeep(child)
                                });
                            }

                            items.forEach(item => collectItemsDeep(item))

                            return answer;
                        }

                        const itemsPrevByKey = _.indexBy(flattenItems(prev), "key");
                        const itemsCurrentByKey = _.indexBy(flattenItems(current), "key");

                        const getUniqueItemKeys = (prev: StatsGroupedItem[], current: StatsGroupedItem[]): string[] => {
                            const answer = []

                            prev?.forEach(item => answer.push(item.key))
                            current?.forEach(item => answer.push(item.key))

                            return _.unique(answer)
                        }

                        const transformDeep = (itemKey: string): StatsGroupedChangedItem => {
                            const prev = itemsPrevByKey[itemKey] as StatsGroupedItem
                            const current = itemsCurrentByKey[itemKey] as StatsGroupedItem
                            const item = current || prev

                            const getOrEmptyFilteredCountedData = (
                                value: BlockSpotsFilteredCountedData,
                                otherwise: BlockSpotsFilteredCountedData,
                            ): BlockSpotsFilteredCountedData => {
                                if (value) {
                                    return value
                                } else {
                                    return {
                                        filtered: {
                                            count: 0,
                                            bookGoal: 0,
                                        },
                                        total: otherwise.total
                                    }
                                }
                            }

                            return {
                                key: item.key,
                                groupKeyTypes: item.groupKeyTypes,
                                channelId: item.channelId,
                                month: item.month,
                                taskItemId: item.taskItemId,
                                filterReason: item.filterReason,
                                spots: {
                                    current: getOrEmptyFilteredCountedData(current?.spots, prev?.spots),
                                    prev: getOrEmptyFilteredCountedData(prev?.spots, current?.spots),
                                },
                                blocks: {
                                    current: getOrEmptyFilteredCountedData(current?.blocks, prev?.blocks),
                                    prev: getOrEmptyFilteredCountedData(prev?.blocks, current?.blocks),
                                },
                                children: getUniqueItemKeys(prev?.children, current?.children).map(transformDeep)
                            }
                        }

                        return getUniqueItemKeys(prev, current).map(transformDeep)
                    },
                    _getChannelIds: (): ChannelId[] => {
                        const taskItems: Dictionary<TaskItemShortDisplayView> = $ctrl.taskItems

                        return _.unique(Object.keys(taskItems).map(id => taskItems[id].channel_id))
                    },
                    _getModel: (): StatsGroupedChangedItem[] => {
                        if ($ctrl.isLoadingData) {
                            return []
                        } else {
                            const groupKeys = $ctrl.groupCriteria.get()
                            const stats: Map<TaskItemId, TaskItemBookStrategyPredictStats> = new Map(($ctrl.stats as TaskItemBookStrategyPredictStats[]).map(s => [s.task_item_id, s]));
                            const filterReasonKeys = $ctrl.table._getFilterReasonKeys(stats)

                            return $ctrl.table._getGroupedChangedItems(
                                $ctrl.table._getGroupedItems(stats, stats => stats.stats_prev, groupKeys, filterReasonKeys),
                                $ctrl.table._getGroupedItems(stats, stats => stats.stats_current, groupKeys, filterReasonKeys),
                            )
                        }
                    },
                    _getFilterReasonKeys: (stats: Map<TaskItemId, TaskItemBookStrategyPredictStats>) => {
                        const blocks = new Set<string>()
                        const spots = new Set<string>()

                        for (let taskItemStats of stats.values()) {
                            Object.keys(taskItemStats.stats_prev.blocks.filter_reasons).forEach(reason => blocks.add(reason))
                            Object.keys(taskItemStats.stats_current.blocks.filter_reasons).forEach(reason => blocks.add(reason))
                            Object.keys(taskItemStats.stats_prev.spots.filter_reasons).forEach(reason => spots.add(reason))
                            Object.keys(taskItemStats.stats_current.spots.filter_reasons).forEach(reason => spots.add(reason))
                        }

                        return {
                            blocks: blocks,
                            spots: spots,
                        }
                    }
                    // _getModelFromStats: (stats): StatsGroupedItem[] => {
                    //     const rawRows = (() => {
                    //         return [
                    //             {
                    //                 channelId: 123,
                    //                 month: 202212,
                    //                 taskItemId: 123,
                    //                 filterReason: 'abc',
                    //                 spotIds: [123],
                    //                 blockIds: [444],
                    //             }
                    //         ]
                    //     })()
                    //
                    //     const rawTotalRows = (() => {
                    //         return [
                    //             {
                    //                 channelId: 123,
                    //                 month: 202212,
                    //                 taskItemId: 123,
                    //                 spotIds: [123],
                    //                 blockIds: [444],
                    //             }
                    //         ]
                    //     })()
                    //
                    //     const groupedRows = (() => {
                    //         return [
                    //             {
                    //                 filterReason: 'abc',
                    //                 spots: [
                    //                     {
                    //                         filtered: {count: 5, bookGoal: 22.5},
                    //                         total: {count: 15, bookGoal: 72.5},
                    //                     }
                    //                 ],
                    //                 blocks: [
                    //                     {
                    //                         filtered: {count: 5, bookGoal: 22.5},
                    //                         total: {count: 15, bookGoal: 72.5},
                    //                     }
                    //                 ],
                    //                 children: [
                    //                     {
                    //                         filterReason: 'abc',
                    //                         channelId: 123,
                    //                         spots: [
                    //                             {
                    //                                 filtered: {count: 5, bookGoal: 22.5},
                    //                                 total: {count: 15, bookGoal: 72.5},
                    //                             }
                    //                         ],
                    //                         blocks: [
                    //                             {
                    //                                 filtered: {count: 5, bookGoal: 22.5},
                    //                                 total: {count: 15, bookGoal: 72.5},
                    //                             }
                    //                         ],
                    //                     }
                    //                 ]
                    //             }
                    //         ]
                    //     })()
                    //
                    //     return groupedRows
                    // },
                };
            })();

            $scope.$watchGroup(["$ctrl.taskItems", "$ctrl.stats"], function () {
                $ctrl.table._refresh();
            });
        }
    })
    .component("lcTableRowLabel", {
        templateUrl: "lc-table-row-label",
        bindings: {
            row: "=",
        },
        controller: function (
            _cBookingStrategyPredictStatsTableRowLabelFilter,
        ) {
            const $ctrl = this
            const row: TableRow = $ctrl.row

            $ctrl.label = _cBookingStrategyPredictStatsTableRowLabelFilter(row)
        }
    })
    .component("lcTableRowSpots", {
        templateUrl: "lc-table-row-spots",
        bindings: {
            row: "=",
            channelIds: "=",
        },
        controller: function () {
            const $ctrl = this

        }
    })
    .component("lcTableRowBlocks", {
        templateUrl: "lc-table-row-blocks",
        bindings: {
            row: "=",
            channelIds: "=",
        },
        controller: function () {
            const $ctrl = this

        }
    })
    .component("lcTableRowChange", {
        templateUrl: "lc-table-row-change",
        bindings: {
            row: "=",
            change: "=",
            unitType: "=",
            handleToggleUnitType: "=",
            channelIds: "=",
            isReverse: "=",
        },
        controller: function (
            gettextCatalog,
            _percentageOfFilter,
            _convert,
            _channels,
            _bookGoalToHumanFilter,
            _bookGoalTypeLabelFilter,
        ) {
            const $ctrl = this
            const row: TableRow = $ctrl.row
            const isReverse = !!$ctrl.isReverse
            const isReverseSign = _convert.toSign(!isReverse)
            const channelIds: ChannelId[] = $ctrl.channelIds

            _channels.api.mapByIdsAndCache().then(channels => {
                const channelsCurrent: Channel[] = ((row.channelId) ? [row.channelId] : channelIds)
                    .map(channelId => channels[channelId.toString()])
                    .filter(c => !!c);

                const minute = _.unique(channelsCurrent.map(c => c.minute))
                const isSameChannelType = minute.length === 1
                const isMinute = minute[0]

                const unitType: UnitType = $ctrl.unitType || ((isSameChannelType) ? UnitType.BOOK_GOAL : UnitType.COUNT)
                const change: BlockSpotsFilteredCountedDataChange = $ctrl.change

                const getValueUnit = (data: { count: number, bookGoal: BookGoal }, unitType: UnitType): number => {
                    switch (unitType) {
                        case UnitType.BOOK_GOAL:
                            return data.bookGoal
                        case UnitType.COUNT:
                            return data.count
                    }
                }

                const getValue = (total: { count: number, bookGoal: BookGoal }, data: { count: number, bookGoal: BookGoal }, unitType: UnitType): number => {
                    if (isReverse) {
                        return getValueUnit(total, unitType) - getValueUnit(data, unitType)
                    } else {
                        return getValueUnit(data, unitType)
                    }
                }

                const total = getValueUnit(change.current.total, unitType)
                const valueToDisplayCurrent = getValue(change.current.total, change.current.filtered, unitType);
                const valueToDisplayPrev =  getValue(change.current.total, change.prev.filtered, unitType);
                const changeValue = valueToDisplayCurrent - valueToDisplayPrev
                const changePercentage = _percentageOfFilter(_.roundTo(changeValue, 3), _.roundTo(total, 3))
                const valueToDisplayCurrentPercentage = _percentageOfFilter(_.roundTo(valueToDisplayCurrent, 3), _.roundTo(total, 3))

                $ctrl.total = total
                $ctrl.displayedValue = valueToDisplayCurrent
                $ctrl.displayedPercentage = valueToDisplayCurrentPercentage
                $ctrl.changeValue = changeValue
                $ctrl.changePercentage = changePercentage
                $ctrl.changeClass = (changeValue * isReverseSign < 0) ? '_color--green' : '_color--red'
                $ctrl.tooltip = (() => {
                    const rows = []

                    if (isSameChannelType) {
                        rows.push(
                            gettextCatalog.getString(
                                "{{bookGoalType}}: {{toDisplay}} of {{total}}",
                                {
                                    bookGoalType: _bookGoalTypeLabelFilter(isMinute),
                                    toDisplay: _bookGoalToHumanFilter(getValue(change.current.total, change.current.filtered, UnitType.BOOK_GOAL), isMinute),
                                    total: _bookGoalToHumanFilter(getValueUnit(change.current.total, UnitType.BOOK_GOAL), isMinute),
                                }
                            )
                        )
                    }

                    rows.push(
                        gettextCatalog.getString(
                            "Count: {{toDisplay}} of {{total}}",
                            {
                                toDisplay: getValue(change.current.total, change.current.filtered, UnitType.COUNT),
                                total: getValueUnit(change.current.total, UnitType.COUNT),
                            }
                        )
                    )

                    return rows.join("<br>")
                })()
            })
        }
    });