import {ComputableMap} from "./computableMap";

export class UniqueMap<K, SK> {

    private data = new ComputableMap<K, Set<SK>>(k => new Set())

    push(key: K, subKey: SK): boolean {
        const subKeys = this.data.get(key);

        if (subKeys.has(subKey)) {
            return false;
        } else {
            subKeys.add(subKey);

            return true;
        }
    }

}