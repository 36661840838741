import {Moment} from './moment';

export type QueueTaskId = number

export enum QueueTaskStatus {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    EXCEPTION = 'EXCEPTION',
}

export type QueueTask = {
    id: QueueTaskId,
    status: QueueTaskStatus,
    type: string,
    priority: number,
    context: string,
    finished_at: Moment,
    posted_on: Moment,
    queued_on: Moment,
    started_at: Moment,
    status_on: Moment,
}
