declare var angular: any
declare var $: any

export class RouterService {

    api = {
        admin: {
            queueTask: {
                list: () => "/api/admin/queue-tasks/",
                filterItems: () => "/api/admin/queue-tasks/filter-items",
                createTask: () => "/api/admin/queue-tasks/new",
                setStatus: () => "/api/admin/queue-tasks/set-status",
            },
        }
    }

    constructor() {
    }
}

angular.module("app")
    .factory("_routerTs", function () {
        return new RouterService()
    }
)
