import type {IHttpPromise, IPromise} from 'angular';

declare var angular: any

export class FilterItemService {

    constructor(
        private _filterItemsPostProcessor: any,
        private _filterItem: any,
    ) {
    }

    getDataAndPostProcess<T>(promise: IHttpPromise<T>, postProcessor?: any, itemsPostProcessor?: any): IPromise<any> {
        itemsPostProcessor = itemsPostProcessor || this._filterItemsPostProcessor;

        return promise.then(response => {
            const items = itemsPostProcessor(response.data);

            return items.map(item => {
                if (postProcessor) {
                    item = postProcessor(item);
                }

                return this._filterItem.postProcess(item);
            })
        })
    }

}

angular.module("app")
    .factory("_filterItemTs", function (
        _filterItemsPostProcessor,
        _filterItem
        ) {
            return new FilterItemService(
                _filterItemsPostProcessor,
                _filterItem,
            )
        }
    )
