import {AdminQueueTaskApiService} from '../admin/adminQueueTaskApi.service';
import {CollectionEntity} from '../domain/collectionEntity';
import {QueueTask, QueueTaskStatus} from '../domain/queueTask';
import {RouterService} from '../common/router.service';

declare var _: any
declare var angular: any
declare var moment: () => moment.Moment

type Ctrl = {
    breadcrumb: any,
    filter: any,
    selection: any,
    lastSynced: any,
    data: CollectionEntity<QueueTask>,
    table: any,
    actions: any,
}

angular.module("app")
    .controller("AdminQueueTasksPage", function (
        $uibModal,
        $rootScope,
        $scope,
        _routerTs: RouterService,
        _adminQueueTaskApi: AdminQueueTaskApiService,
        _convert,
        _modal,
        _filter,
        _filterWrapper,
        _selection,
        _filterItem,
        gettextCatalog,
        _notify,
        _admin,
        _sellingDirectionId,
    ) {
        var $ctrl = this as Ctrl;

        (function configureBreadcrumb() {
            $ctrl.breadcrumb = {
                before: [
                    {
                        path: "app.root"
                    }
                ]
            };
        }());

        (function configureFilters() {
            $ctrl.filter = {
                data: _filter.init({
                    getSelectedContainer: function () {
                        return $scope.$v.f;
                    }
                }),
                month: {
                    set: function (date) {
                        $ctrl.filter._wrapper.setFilterMonth(date);
                    },
                    current: function () {
                        $ctrl.filter.month.set(moment());
                    },
                    next: function () {
                        $ctrl.filter.month.set(moment().add(1, "months"));
                    }
                },
                sellingDirectionIds: {
                    federal: function () {
                        $ctrl.filter.data.addSelected(_filterItem.toSellingDirectionId(_sellingDirectionId.FEDERAL))
                    },
                    regional: function () {
                        $ctrl.filter.data.addSelected(_filterItem.toSellingDirectionId(_sellingDirectionId.REGIONAL))
                    },
                },
                syncTaskTypes: {
                    mediaPlanItems: function () {
                        $ctrl.filter.data.addSelected(_filterItem.toSyncTaskType("MEDIA_PLAN_ITEMS"))
                    },
                    programBreaks: function () {
                        $ctrl.filter.data.addSelected(_filterItem.toSyncTaskType("PROGRAM_BREAKS"))
                    },
                    spots: function () {
                        $ctrl.filter.data.addSelected(_filterItem.toSyncTaskType("SPOTS"))
                    },
                }
            };

            $ctrl.filter._wrapper = _filterWrapper.get($ctrl.filter.data);

            _adminQueueTaskApi.getFilterItems().then(function (items) {
                $ctrl.filter.data.setItems(items);
            });
        }());

        (function configureSelection() {
            $ctrl.selection = _selection.init({
                getItemsCurrentPage: function () {
                    return ($ctrl.data) ? $ctrl.data.items : [];
                }
            })
        }());

        (function configureLastSynced() {
            $ctrl.lastSynced = {
                _refreshItemsToDisplay: () => {
                    const itemsByType = _.groupBy(($scope.$v.f.items || []).map(_filterItem.uniqueIdToObject), r => r.type);
                    const itemsSyncTaskType = itemsByType["syncTaskType"] || []
                    const itemsSellingDirectionIds = itemsByType["sellingDirectionId"] || []

                    if (itemsSellingDirectionIds.length === 1 && itemsSyncTaskType.length === 1) {
                        _admin.api.sync.getLastSyncedByKeys(itemsSyncTaskType[0].id, itemsSellingDirectionIds[0].id, true).then(data => {
                            $ctrl.lastSynced.itemsToDisplay = _.sortBy(data, r => r.month)
                        })
                    } else {
                        $ctrl.lastSynced.itemsToDisplay = null
                    }
                }
            }

            $scope.$watch("$v.f", () => $ctrl.lastSynced._refreshItemsToDisplay(), true);
        }());

        (function configureTable() {
            $ctrl.table = {
                callServer: function (tableState) {
                    if (tableState.sort.predicate) {
                        $scope.$v.f.order = _convert.stSortToString(tableState.sort);
                    }
                    if (tableState.pagination) {
                        $scope.$v.f.page = (tableState.pagination.start / tableState.pagination.itemsByPage) + 1;
                    }
                },
                setLoading: function (value) {
                    $ctrl.table.isLoading = value;
                }
            };
        }());

        (function configureActions() {
            $ctrl.actions = {
                createTasks: function () {
                    _admin.api.certificates.list().then(certificates => {
                        $uibModal.open({
                            templateUrl: "/components/_c-admin-queue-tasks-create-modal.html",
                            scope: $rootScope.$new(),
                            controller: function ($scope, $uibModalInstance) {
                                const $c = $scope.$ctrl = this;

                                const simpleSyncJobs = ['AFFINITY', 'FILMS']

                                $c.form = {
                                    sync_task_types: [],
                                    selling_direction_ids: [],
                                    certificates: ['BASE'],
                                    context: '',
                                };

                                $c.syncTaskTypes = {
                                    isContextLessJobSelected: () => {
                                        return simpleSyncJobs.some(j => $c.syncTaskTypes.has(j))
                                    },
                                    has: (syncJob) => {
                                        return $c.form.sync_task_types.includes(syncJob)
                                    },
                                    toggle: (syncJob) => {
                                        $c.form.sync_task_types.addOrRemove(syncJob)
                                    },
                                    canCheck: (syncJob) => {
                                        if (simpleSyncJobs.indexOf(syncJob) >= 0) {
                                            return $c.form.sync_task_types.every(syncJob => simpleSyncJobs.indexOf(syncJob) >= 0)
                                        } else {
                                            return !$c.form.sync_task_types.some(syncJob => simpleSyncJobs.indexOf(syncJob) >= 0)
                                        }
                                    }
                                }

                                $c.sellingDirectionIds = {
                                    has: (sellingDirectionId) => {
                                        return $c.form.selling_direction_ids.includes(sellingDirectionId)
                                    },
                                    toggle: (sellingDirectionId) => {
                                        $c.form.selling_direction_ids.addOrRemove(sellingDirectionId)
                                    }
                                };

                                $c.certificates = {
                                    items: certificates,
                                    has: (key) => {
                                        return $c.form.certificates.includes(key)
                                    },
                                    toggle: (key) => {
                                        $c.form.certificates.addOrRemove(key)
                                    }
                                };

                                $c.formGetTargetUri = function () {
                                    return _routerTs.api.admin.queueTask.createTask();
                                };

                                $c.formOnSuccess = function (answer) {
                                    _notify.info(gettextCatalog.getString("Tasks has been created"));
                                    $uibModalInstance.close();
                                    _refreshItems()
                                };

                                $c.close = function () {
                                    $uibModalInstance.close();
                                };
                            }
                        });
                    })
                },
                setStatus: {
                    statuses: Object.keys(QueueTaskStatus),
                    doSet: (status: QueueTaskStatus) => {
                        _adminQueueTaskApi.setStatus(
                            $ctrl.selection.get().filter(item => item.status !== QueueTaskStatus.IN_PROGRESS).map(item => item.id),
                            status,
                        ).then(() => {
                            _notify.info(gettextCatalog.getString("Status has been updated"));
                            _refreshItems()
                        })
                    }
                },
            }
        }());

        (function watchAndRefreshTasks() {
            $scope.$watch("$v.f", _onFilterChange, true);
        }());

        function _onFilterChange() {
            _refreshItems();
        }

        function _refreshItems() {
            $ctrl.table.setLoading(true);

            _adminQueueTaskApi.list(_getFilter()).then(function (answer) {
                $ctrl.data = answer;
                $ctrl.table.setLoading(false);
            });
        }

        function _getFilter() {
            var f = $scope.$v.f;

            return {
                page: f.page,
                order: f.order,
                filter: {
                    items: f.items
                }
            }
        }
    });
