import {RouterService} from '../common/router.service';
import {CollectionEntity} from '../domain/collectionEntity';
import {QueueTask, QueueTaskId, QueueTaskStatus} from '../domain/queueTask';
import {HttpService} from '../common/http.service';
import {QueueTaskTransformerService} from '../_queueTask';
import type {IPromise} from 'angular';
import {FilterItemService} from '../common/filterItem.service';

declare var angular: any

export class AdminQueueTaskApiService {

    constructor(
        private $http: ng.IHttpService,
        private _http: HttpService,
        private _filterItem: FilterItemService,
        private _router: RouterService,
        private _queueTaskTransformer: QueueTaskTransformerService,
    ) {
    }

    list(filter: any): IPromise<CollectionEntity<QueueTask>> {
        return this._http.getListDataAndTransform(
            this.$http.post<CollectionEntity<QueueTask>>(this._router.api.admin.queueTask.list(), filter),
            this._queueTaskTransformer,
        )
    }

    getFilterItems(): IPromise<any> {
        return this._filterItem.getDataAndPostProcess(
            this.$http.get(this._router.api.admin.queueTask.filterItems())
        )
    }

    setStatus(queueTaskIds: QueueTaskId[], status: QueueTaskStatus): IPromise<any> {
        return this._http.getData(
            this.$http.post(this._router.api.admin.queueTask.setStatus(), {queue_task_ids: queueTaskIds, status: status})
        )
    }

}

angular.module("app")
    .factory("_adminQueueTaskApi", function (
        $http,
        _httpTs: HttpService,
        _routerTs: RouterService,
        _queueTaskTransformer: QueueTaskTransformerService,
        _filterItemTs: FilterItemService,
    ) {
        return new AdminQueueTaskApiService(
            $http,
            _httpTs,
            _filterItemTs,
            _routerTs,
            _queueTaskTransformer,
        )
    })
