import {BookGoal} from "./book";
import {SpotId} from "./spot";
import {TaskItemId} from "./taskItem";
import {BlockId} from "./block";

export type TaskItemInventoryStats = {
    task_item_id: TaskItemId,
    prime: TaskItemInventoryStatsItem,
    offprime: TaskItemInventoryStatsItem,
    filter_reasons: {[filterReason: string]: TaskItemInventoryStatsUnits}
}

export type TaskItemInventoryStatsItem = {
    available: TaskItemInventoryStatsUnits,
    filtered: TaskItemInventoryStatsUnits,
}

export type TaskItemInventoryStatsUnits = {
    book_goal: BookGoal,
    count: number,
    program_break_ids: BlockId[],
}

export type TaskItemSpotsStats = {
    task_item_id: TaskItemId,
    prime: TaskItemSpotsStatsItem,
    offprime: TaskItemSpotsStatsItem,
    filter_reasons: {[filterReason: string]: TaskItemSpotsStatsUnits}
}

export type TaskItemSpotsFilterStats = {
    task_item_id: TaskItemId,
    filter_reasons: {[filterReason: string]: TaskItemSpotsStatsUnits}
}

export type TaskItemSpotsStatsItem = {
    available: TaskItemSpotsStatsUnits,
    filtered: TaskItemSpotsStatsUnits,
}

export type TaskItemSpotsStatsUnits = {
    book_goal: BookGoal,
    count: number,
    spot_ids: SpotId[]
}

export type TaskItemBookStrategyPredictStats = {
    task_item_id: TaskItemId;
    stats_prev: TaskItemBookStrategyPredictStatsStats,
    stats_current: TaskItemBookStrategyPredictStatsStats,
    block_details: {
        [blockId: string]: {
            book_goal: BookGoal,
            is_prime: boolean,
        }
    },
    spot_details: {
        [spotId: string]: {
            book_goal: BookGoal,
            is_prime: boolean,
        }
    },
}

export type TaskItemBookStrategyPredictStatsStats = {
    blocks: TaskItemInventoryStats,
    spots: TaskItemSpotsFilterStats,
}